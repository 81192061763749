import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { CAMemberData, cpdInfo, getCslDheerajapi, memberDetails, getCreditHours } from '../../actions/Utils';
import { icabInstance } from '../../config/Api';
import MemberLeftsidebar from './MemberLeftsidebar';

class MemberDashbaord extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      content: {},
      content_loading: true,
      memberinfo: [],
      cpdinfo: [],
      billing:[],
      creditHours: {},
      upcoming_event:[]
    }

  }

  componentDidMount = () => {
    /* const requestData = {
      stext: localStorage.getItem('member_id'),
      pageno: 1,
      itemno: 1,
    }
    this.props.CAMemberData(requestData).then((data) => {
      this.setState({
        memberinfo: data.memberinfo
      })
    }); */
    if(localStorage.getItem('member_id'))
    {
      const requestData = {
        EnNo: localStorage.getItem('member_id'),
        
      }
      this.props.memberDetails(requestData).then((data) => {
        
        this.setState({
          memberinfo: data
        })
      });
     
      icabInstance.get('IcabApi/TWCPDevent/TrainingWorkshopsCPDEvents').then(({ data }) => {
        this.setState({upcoming_event:data.payload});
  
      }).catch(() => {
        this.setState({upcoming_event:[]});
  
      });
      const cpdrequestData = {
        EnRl: localStorage.getItem('member_id')
        
      }
      // this.props.cpdInfo(cpdrequestData).then((data) => {
      //   this.setState({
      //     cpdinfo: data
      //   });
      // });
     
      this.GetInvoice();
      this.getCreditHours();
    }
    // this.forceUpdate();
    
  }

  GetInvoice(){
    this.setState({
      billing:[]
    });
    const billingRequest = {
      userkeyid: localStorage.getItem('member_id'),
      customerType: 'Member',
      "_": new Date().getTime()
    }
    this.props.getCslDheerajapi(billingRequest, 'GetInvoice', 'get').then((data) => {
        this.setState({
          billing:data.Result
        });
        this.forceUpdate();
    });
  }

  getCreditHours(){
    this.setState(
      { creditHours: {}}
    );
    this.props.getCreditHours({username: localStorage.getItem('member_id')}, '/api/v1/credit-hours/summary/username')
      .then((res)=>{
        if(res){
          this.setState(
            { creditHours: res}
          );
        }
      })
  }

  static getDerivedStateFromProps(props, state) {
    return {
      committe: props.committe
    }
  }
  handleActivityModal = (content) => {
    this.setState({
      modal_content: content,
      is_open_modal: true
    })
  }

  handleCloseModal = (status) => {

    this.setState({
      modal_content: null,
      is_open_modal: false
    })

  }
  handleMemberShowHide = (status) => {

    this.setState({
      show_all_member: status
    })

  }
  handleTorShowHide = (status) => {

    this.setState({
      show_all_tor: status
    })

  }

  scrollToTestDiv = (id) => {
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });


  }

  calBillingTodalDue = () => {

    const billing = this.state.billing;
    
    // if(billing.length) {
    //   var total_due = '';
    //   var total_paid = '';
      
    //   billing.map((row,key)=>{
    //     var receivable = row['Receivable/Advance'];
    //     var paid = row['Paid/Adjusted'];
        
    //   })

    // }
    return '-';
  }


  render() {


    const { memberinfo, content_loading } = this.state;
    // const { cpdinfo } = this.state;
    const { billing, creditHours } = this.state;

    var today = new Date();
    var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

    const memberdata = Object.keys(memberinfo).length ? memberinfo.payload[0] : false;

    setTimeout(() => {
      this.setState({
        content_loading: false
      });
    }, 3000);
  
    return (

      <Fragment>
        {content_loading? 
          <div id="loader-1" className='loader-online-page'>
              <span></span><span></span><span></span><span></span><span></span>
          </div> :

        <section className="dashboard">
          <div className="container">

            <div className="row">

              <div className="col-md-3">

                <div className="member-dashboard-menu mb-5">

                  <MemberLeftsidebar activemenu="dashboard" />

                </div>

              </div>

              <div className="col-md-9">
                <div className="member-dashboard-content">
                  <div className="members-title">
                    <h3>Member’s Info</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      {memberdata?<Fragment>
                        <div className="member-box">
                          {memberdata.imG_PATH&&<Fragment>
                              <img src={memberdata.imG_PATH} />
                          </Fragment>}
                          <h3>{memberdata.memberNameFul+" "+memberdata.acA_FCA+" ("+memberdata.enNo+")"}</h3>
                          <h6>{memberdata.position}</h6>
                          <h6>{memberdata.nameOfOrg}</h6>
                          <p>
                            {memberdata.address}
                          </p>
                        </div>
                      </Fragment>:''}
                    </div>
                    <div className="col-md-7">
                      <div className="member-box user-details-box">
                        <h3>User Details</h3>
                        <table>
                          <tbody>
                            <tr>
                              <td>Cell Phone:</td>
                              <td>{memberdata ? memberdata.cellPhone : ''}</td>
                            </tr>
                            <tr>
                              <td>Email:</td>
                              <td> 
                                {
                                    memberdata.emailAddress1 || memberdata.emailAddress2?
                                    (memberdata.emailAddress1 ? memberdata.emailAddress1:'') +(memberdata.emailAddress1 && memberdata.emailAddress2 ?', ':'')+(memberdata.emailAddress2 ?memberdata.emailAddress2:'')
                                    :
                                    ''
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Status:</td>
                              <td>{memberdata ? memberdata.occoupation_Status : ''}</td>
                            </tr>
                            <tr>
                              <td>COP:</td>
                              <td>{memberdata ? memberdata.coP_Status : ''}</td>
                            </tr>
                          </tbody>
                        </table>
                        {
                          
                          <Fragment >
                          <h3>CPD Credit Hours ({date})</h3>
                          <table>
                            <tbody>
                              <tr>
                                <td>Current Year:</td>
                                <td>{creditHours ? creditHours.currentYearCreditHours : '0'}</td>
                              </tr>
                              <tr>
                                <td>Last Year:</td>
                                <td>{creditHours ? creditHours.lastYearCreditHours : '0'}</td>
                              </tr>
                              <tr>
                                <td>Last 5 Years:</td>
                                <td>{creditHours ? creditHours.lastFiveYearsCreditHours : '0'}</td>
                              </tr>
                            </tbody>
                          </table>
                          <h3><a href='https://cpd.x.org.bd' target='_blank'>CPD Details</a></h3>
                          </Fragment>
                        }
                      </div>
                    </div>
                  </div>
                  {billing.length == 0 ? 
                      <div className="member-box"><button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.GetInvoice()}>
                            Get Invoice information <i class="fa fa-retweet" aria-hidden="true"></i>
                      </button></div> : 
                  
                  <div className="member-box">
                    <Fragment>
                      <h3>Bills & Dues (As on {date})</h3>
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.GetInvoice()}>
                            Reload Invoice Info <i class="fa fa-retweet" aria-hidden="true"></i>

                      </button>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{width:210+"px"}}>Total Dues:</td>
                            <td>{billing.length ? billing[0].NetDues+billing[0].UnadjustedAdvance : 0}</td>
                          </tr>
                          <tr>
                            <td>Unadjusted Advance:</td>
                            <td>{billing.length ? billing[0].UnadjustedAdvance:0}</td>
                          </tr>
                          <tr>
                            <td>Net Dues:</td>
                            <td>{billing.length ? billing[0].NetDues:0}</td>
                          </tr>
                          <tr>
                            <td>Dues Breakup:</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="table-style table-responsive member-online-payment-table mt-3">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Invoice<br/> No</th>
                            <th>Year</th>
                            <th>Particulars</th>
                            <th>Invoice <br/> Amount</th>
                            <th>Paid <br/> Amount</th>
                            <th>Due<br/> Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            billing.map((row,key)=>{
                              return (
                                <Fragment key={key}>
                                  <tr>
                                    <td>{row.InvoiceNo}</td>
                                    <td>{row.FinancialYear}</td>
                                    <td>{row.Particulars}</td>
                                    <td>{row.InvoiceAmount}</td>
                                    <td>{row.PaidAmount}</td>
                                    <td>{row.DuesAmount}</td>
                                  </tr>
                                </Fragment>
                              )
                            })
                          }
                        </tbody>
                      </table>
                      </div>
                    </Fragment>
                       
                  </div>}
                  {
                    this.state.upcoming_event.length>0?
                    <Fragment>
                      <div className="members-title">
                        <h3>Upcoming Training / Workshop/ CPD Events</h3>
                      </div>
                      <div className="member-box upcoming-training-box">
                        <div className="table-style table-responsive member-upcoming-event-table">
                          <table className="table">
                            <thead>
                              <tr align="center">
                                <th>Event Type</th>
                                <th>Topic</th>
                                <th>Resource Person</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Credit<br/>Hours</th>
                                {/* <th>Apply /<br/>Register</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.upcoming_event.map((row,key)=>{
                                  return <tr key={key}>
                                  <td>{row.eventType}</td>
                                  <td className="text-left">{row.topic}</td>
                                  <td className="text-left">{row.resourcePerson}</td>
                                  {/* <td>{new Date(row.startDate).toLocaleDateString('en-GB')}</td>
                                  <td>{new Date(row.endDate).toLocaleDateString('en-GB')}</td> */}
                                  
                                  <td>{row.startDate}</td>
                                  <td>{row.endDate}</td>
                                  <td className="text-center">{row.creditHours}</td>
                                  {/* <td>
                                    <div className="register-event-btn-div">
                                      <a className="btn btn-success btn-register-event">Apply</a>
                                    </div>
                                  </td> */}
                                </tr>
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Fragment>
                    :''
                  }
                </div>

              </div>


            </div>

          </div>
        </section>}

      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  committe: state.contents.committe_content.singlecommitte,
})

const mapDispatchToProps = (dispatch) => ({
  CAMemberData: (requestData) => dispatch(CAMemberData(requestData)),
  cpdInfo: (requestData) => dispatch(cpdInfo(requestData)),
  getCslDheerajapi: (requestData, actionName, RequestMethod) => dispatch(getCslDheerajapi(requestData, actionName, RequestMethod)),
  getCreditHours: (requestData, actionName, RequestMethod) => dispatch(getCreditHours(requestData, actionName, RequestMethod)),
  memberDetails: (requestData) => dispatch(memberDetails(requestData)),

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberDashbaord));