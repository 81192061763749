import React, { useEffect } from 'react';

const Chatbot = () => {
    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        script.src = "https://chatwidget.icab.org.bd/chatbot-widget.01092024.js?t=27082024";
        script.async = true;

        // Append it to the document body
        document.body.appendChild(script);

        // Cleanup script when component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div
            className="feye-ai-chatbot-container"
            style={{ fontFamily: 'Arial' }}
            data-websocket-url="https://spsapi.icab.org.bd/ws"
        >
        </div>
    );
};

export default Chatbot;
