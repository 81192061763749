
import InnerHTML from 'dangerously-set-inner-html';
import $ from 'jquery';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFooterSection, getHomePageModal, newsSubscribe } from "../../actions/Utils";
import Htmlcontent from '../Htmlcontent';
import HomeModal from '../popup/HomeModal';
import MemberShip from './MemberShip';
import Sitemaintenance from '../popup/Sitemaintenance';
import Chatbot from '../../components/chatbot/Chatbot'
class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            footerdata: {},
            validator:{
                name:'',
                email:'',
                concern:'',
                message:'',
            },
            message:'',
            message_type:'',
            home_modal:'',
            modal_show:false,
            modalLoad:0,
        }
      
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = () => {
        $(document).ready(function($) {
            $('.copyright-year').html(new Date().getFullYear());
        });
        this.props.getFooterSection();

        setTimeout(() => {
            this.props.getHomePageModal().then((data)=>{
                this.setState({
                    home_modal: data,
                    modal_show:data?true:false,
                })
            });
        }, 500);
    }
   
    static getDerivedStateFromProps(props, state) {

        return {
            footerdata: props.footer
        }
    }

    handleMessageClose = () => {
        this.setState({message:'',message_type:''})
    }
    
    modalLoadcount = () => {
        this.setState({modalLoad:1})
    }

    handleSubmit = () => {



        const userFormData = {

            email: this.email.value,

        }

        const resetValidator = {

            email: '',

        }
        var validation = {};

        if (userFormData.email == '') {
            validation.email = 'The email field is required.';
        }



        if (Object.keys(validation).length) {
            this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

        }
        else {


            this.props.newsSubscribe(userFormData).then((res) => {
                this.email.value = '';

                this.setState({ message: res.data.success, message_type: 'success', validator: { ...resetValidator } })
            })
                .catch(error => {


                    if (typeof error.response.data.errors != "undefined") {
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            validation[key] = value;
                        }
                        this.setState({ validator: validation })
                    }
                    else if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data)) {
                                html += `${value}`;
                            }

                        }


                    } else if (error.request) {
                        
                        // console.log('error.request ',error.request);
                    } else {
                       
                        // console.log('Error', error.message);
                    }
                    if (html) {
                        this.setState({ message: html, message_type: 'danger', validator: { ...resetValidator } })
                    }

                })
        }


    }

    handleCloseModal = () => {
      
        this.setState({
            modal_show:false,
        })
    }
    render() {

        const { footerdata,modal_show,home_modal,modalLoad } = this.state;
  
        const {validator} = this.state;
        var sliderdata={};
        if(Object.keys(footerdata).length>0)
        { 
             sliderdata = footerdata.membership_section.contents[0].slider;
        }
        return (
            <Fragment>
               { sliderdata.length>0? <MemberShip sliderdata={sliderdata} /> :''}
                <footer>

                    <div className="container">

                        <div className="footer-content">

                            

                        {footerdata.footer_address && <Fragment>{footerdata.footer_address.length ? <Fragment>
                                <Htmlcontent data={footerdata.footer_address[0]['contents']['description']} />
                            </Fragment> : ''}</Fragment>}

                            
                          
                            {footerdata.footer_nav && footerdata.footer_nav.map((row, key) => {
                                return (
                                    <Fragment key={key}>
                                       

                                            <div className="footer-menu">

                                                {row.title && <h3>{row.title}</h3>}

                                                <ul>

                                                    {row.children.length && row.children.map((crow, ckey) => {
                                                        return (
                                                            <Fragment key={ckey}>
                                                                <li>
                                                                    {crow.link_type == 0 && <Link to={{
                                                                        pathname: `${crow.url}`,
                                                                    }} target="_blank">{crow.title}
                                                                    </Link>}
                                                                    {crow.link_type == 1 && <Link to={`${process.env.PUBLIC_URL}${crow.url}`}>{crow.title}</Link>}
                                                                </li>
                                                            </Fragment>
                                                        )

                                                    })}


                                                </ul>

                                            </div>

                                       
                                    </Fragment>
                                )
                            })}

                        </div>

                        <hr />

                    </div>



                    <div className="container">

                        <div className="row">

                            <div className="col-md-9">

                                <div className="subscribe-newsletter">

                                    <h3>Subscribe to ICAB Publications</h3>

                                    <form>

                                    {this.state.message !='' && this.state.message_type =='success' && <div className="col-sm-12" >
                                <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                    {this.state.message}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>}
                            {this.state.message !='' && this.state.message_type =='danger' && <div className="col-sm-12">
                                <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                    {this.state.message}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>}


                                        <input type="text" ref={(input) => this.email = input} className={validator.email ? 'form-control input-error' : 'form-control'} placeholder="What’s your mail" />
                                       
                                        <input type="button" className="btn btn-primary" value="Subscribe" name="" onClick={this.handleSubmit} />

                                        {validator.email && <span className="help-block form-text text-danger">{validator.email}</span>}
                                      

                                    </form>

                                </div>

                            </div>

                            <div className="col-md-3">

                                <div className="social-icon">

                                <a href="https://www.facebook.com/CA.Bangladesh" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>

                                <a href="https://www.youtube.com/channel/UCRI2aiAKwB4YsMaJtMM60gQ" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a>

                                <a href="https://www.linkedin.com/school/the-institute-of-chartered-accountants-of-bangladesh/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                
                                <a href="https://www.instagram.com/ca_bangladesh/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>

                                </div>

                            </div>



                        </div>

                    </div>



                    <div className="copyright">

                        <div className="container" >

                        {footerdata.footer_copy_right &&  <InnerHTML  html={footerdata.footer_copy_right[0].contents.description}/>}

                        </div>

                    </div>


                </footer>

                {/* {modal_show&&<HomeModal data={home_modal} handleCloseModal={()=>this.handleCloseModal()}/>}         */}
               {/* {modalLoad===0&&<HomeModal/> } */}
               {/* <Sitemaintenance/>   */}
               <Chatbot />
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    footer: state.footer,
})

const mapDispatchToProps = (dispatch) => ({
    getFooterSection: (requestData) => { dispatch(getFooterSection(requestData)) },
    newsSubscribe: (requestData) => dispatch(newsSubscribe(requestData)),
    getHomePageModal: (requestData) => dispatch(getHomePageModal(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

